<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />
    <div class="apipe-cloud-course-container-madeSolution">
      <div class="pc-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h1 style="margin-bottom: 15px; font-size: 42px; font-weight: 700">
            优制云集成供应链ISC
          </h1>
          <p class="tit">
            优制云工业互联网平台集成供应链ISC，通过智能资源配置、高效生产管理、精准预测管理，助<br />力企业精准把控生产节奏。费用管控与销售管理无缝对接，提升资金周转率。库存实时可视化，<br />避免积压与短缺。应收应付清晰管理，成本核算精准高效。采购与物料计划智能协同，固定资产<br />与总账全面掌控。优制云ISC，让中小微企业的供应链管理更智能、更高效、更透明。
          </p>
        </div>
        <MessageInfo />
      </div>

      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="text-align: left">
          <div class="title">优制云集成供应链ISC</div>
          <div class="titwrap">
            <div class="tit">
              通过需求预测、采购管理、库存控制、物流优化和数据分析等功能，帮助企业优化资源配置，实现降本增效。
            </div>
          </div>
        </div>
      </div>

      <!-- 行业痛点 -->
      <div class="pc-industry d-none d-lg-block">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye1.png" alt="" />
                <h6>供应商管理困难</h6>
                <div class="des">
                  <div>供应商评估和选择缺乏数据支持</div>
                  <!-- <div>或库存不足</div> -->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye2.png" alt="" />
                <h6>供应链透明度低</h6>
                <div class="des">
                  <div>供应链环节信息不透明，响应</div>
                  <div>速度慢</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye3.png" alt="" />
                <h6>库存成本高</h6>
                <div class="des">
                  <div>库存控制不当，导致库存积压</div>
                  <div>或短缺</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye4.png" alt="" />
                <h6>物流成本高</h6>
                <div class="des">
                  <div>物流规划不合理，运输成本高</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
      <div class="mobile-industry d-block d-lg-none">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye1.png" alt="" />
                <h6>供应商管理困难</h6>
                <!-- <div class="des">
                    <div>供应商评估和选择缺乏数据支持</div>
                  </div> -->
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye2.png" alt="" />
                <h6>供应链透明度低</h6>
                <!-- <div class="des">
                    <div>供应链环节信息不透明，响应</div>
                    <div>速度慢</div>
                  </div> -->
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye3.png" alt="" />
                <h6>库存成本高</h6>
                <!-- <div class="des">
                    <div>库存控制不当，导致库存积压</div>
                    <div>或短缺</div>
                  </div> -->
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye4.png" alt="" />
                <h6>物流成本高</h6>
                <!-- <div class="des">
                    <div>物流规划不合理，运输成本高</div>
                  </div> -->
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 解决方案 -->
      <div class="container text-center">
        <div class="pro-tit d-none d-lg-block" style="font-size: 36px">
          解决方案
        </div>
        <div
          class="pro-tit d-block d-lg-none"
          style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
        >
          解决方案
        </div>
        <img
          style="width: 100%"
          src="@/assets/newSite/isc/solution.png"
          alt=""
        />
      </div>

      <!-- 产品能力 -->
      <div class="pc-pro-power d-none d-lg-block">
        <div class="pro-tit" style="font-size: 36px">产品能力</div>
        <div>
          <div class="tabs container text-center">
            <div
              :class="['items', tbx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="tbx == 0">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">销售订单，发货通知单，销售对账</div>
                  <div class="mcot">
                    <div>
                      对销售管理全过程进行追溯，并销售带动整个供应链下游生态圈活跃
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 1">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    采购单价，采购申请，采购订单，采购对账单，采购付款申请单
                  </div>
                  <div class="mcot">
                    <div>
                      企业为了达成生产及销售计划，选择合适的供货单位，确保的质量，数量，时间，及适合价格情况进行经营计划活动工作
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 2">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">生产工单，生产投料单，生产补料单</div>
                  <div class="mcot">
                    <div>
                      生产制造过程追溯，物料去向等调度指挥依据，并与CRM，ISC，MES，WMS系统等集成
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 3">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    采购入库，产品入库，其他入库，销售出库，生产领料，其他出库，调拨单，盘点单，即时库存
                  </div>
                  <div class="mcot">
                    <div>
                      企业经营管理过程所有物件出入库行为进行管控，以见单备货；利用运营管控手段管控账目与实物数量一致性；减少企业财产损失
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 4">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    出入库明细报表，销售日常报表，采购日常报表，生产日常报表
                  </div>
                  <div class="mcot">
                    <div>
                      提供数据分析，统计，挖掘等工具平台，让客户自行根据所需报表配置化发布等
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-pro-power d-block d-lg-none">
        <h4 class="pro-tit">产品能力</h4>
        <div class="">
          <div class="tabs">
            <div
              :class="['items', tbx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="tbx == 0">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">生产BOM、文件管理</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>销售订单，发货通知单，销售对账</div>
                    <div>
                      对销售管理全过程进行追溯，并销售带动整个供应链下游生态圈活跃
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 1">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    采购单价，采购申请，采购订单，采购对账单，采购付款申请单
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      企业为了达成生产及销售计划，选择合适的供货单位，确保的质量，数量，时间，及适合价格情况进行经营计划活动工作
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 2">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    生产工单，生产投料单，生产补料单
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      生产制造过程追溯，物料去向等调度指挥依据，并与CRM，ISC，MES，WMS系统等集成
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 3">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    采购入库，产品入库，其他入库，销售出库，生产领料，其他出库，调拨单，盘点单，即时库存
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      企业经营管理过程所有物件出入库行为进行管控，以见单备货；利用运营管控手段管控账目与实物数量一致性；减少企业财产损失
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 4">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    出入库明细报表，销售日常报表，采购日常报表，生产日常报表
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      提供数据分析，统计，挖掘等工具平台，让客户自行根据所需报表配置化发布等
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/isc/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品优势 -->
      <div class="pc-scene d-none d-lg-block">
        <div class="pro-tit d-none d-lg-block">产品优势</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/ad1.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/ad2.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/ad3.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/ad4.png" alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
      <!-- 产品优势 -->
      <div class="mobile-scene d-block d-lg-none">
        <div
          class="pro-tit d-block d-lg-none"
          style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
        >
          产品优势
        </div>
        <div class="container text-center">
          <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/ad1.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/ad2.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/ad3.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/ad4.png" alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 成功案列 -->
      <section
        class="pc-product d-none d-lg-block"
        style="margin: 80px 0 50px 0"
      >
        <div class="container text-center">
          <h1 class="pro-tit" style="margin-bottom: 20px; font-size: 36px">
            成功案例
          </h1>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in caseList"
              :key="index"
            >
              <span>{{ item.customer }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div
              v-if="caseA.title"
              class="content"
              style="display: flex; padding: 20px 0"
            >
              <div class="wrap" style="padding-right: 10px;">
                <h6 style="font-size: 35px">
                  {{ caseA.title }}
                </h6>
                <p class="p-content">
                  {{ caseA.description }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(
                          `/caseDetail?id=${caseA.id}&tit=${caseA.title}`
                        );
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img :src="caseA.url" width="640px" height="360px" />
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div
              v-if="caseB.title"
              class="content"
              style="display: flex; padding: 20px 0"
            >
              <div class="wrap" style="flex: 60%;padding-right: 10px;">
                <h6 style="font-size: 35px">{{ caseB.title }}</h6>
                <p class="p-content">
                  {{ caseB.description }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(
                          `/caseDetail?id=${caseB.id}&tit=${caseB.title}`
                        );
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img :src="caseB.url" width="640px" height="360px" />
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div
              v-if="caseC.title"
              class="content"
              style="display: flex; padding: 20px 0"
            >
              <div class="wrap" style="flex: 60%;padding-right: 10px;">
                <h6 style="font-size: 35px">{{ caseC.title }}</h6>
                <p class="p-content">
                  {{ caseC.description }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(
                          `/caseDetail?id=${caseC.id}&tit=${caseC.title}`
                        );
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img :src="caseC.url" width="640px" height="360px" />
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </section>

      <section
        class="mobile-product d-block d-lg-none"
        style="margin-top: 40px"
      >
        <div class="container text-center">
          <h3 class="pro-tit">成功案例</h3>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in caseList"
              :key="index"
            >
              <span>{{ item.customer }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div v-if="caseA.title" class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: 700; margin-bottom: 1rem">
                  {{ caseA.title }}
                </h6>
                <p class="p-content">
                  {{ caseA.description }}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="caseA.url" />
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(
                        `/caseDetail?id=${caseA.id}&tit=${caseA.title}`
                      );
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div v-else class="noData">
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div v-if="caseB.title" class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 1rem">
                  {{ caseB.title }}
                </h6>
                <p class="p-content">
                  {{ caseB.description }}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="caseB.url" />
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(
                        `/caseDetail?id=${caseB.id}&tit=${caseB.title}`
                      );
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div v-else class="noData">
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div v-if="caseC.title" class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 1rem">
                  {{ caseC.title }}
                </h6>
                <p class="p-content">
                  {{ caseC.description }}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="caseC.url" />
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(
                        `/caseDetail?id=${caseC.id}&tit=${caseC.title}`
                      );
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div v-else class="noData">
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </section>

      <right-nav-new class="d-none d-lg-block" />
    </div>

    <NewFooter @handleClickChild="handleClickChild" />
    <MobileFooter ref="mofooter" class="d-block d-lg-none" />
  </div>
</template>
            
    <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);

import { caselist } from "@/api/fetch";
export default {
  name: "hardware",
  metaInfo() {
    return {
      title: "优制云集成供应链ISC - 全流程供应链管理智能化方案",
      meta: [
        {
          name: "keywords",
          content:
            "供应链管理,ISC系统,供应链优化,智能供应链,生产管理,工业互联网,工业SaaS系统,优制云,伟班平台",
        },
        {
          name: "description",
          content:
            "优制云工业互联网平台集成供应链ISC系统，通过智能销售管理、高效生产管理、精准预测管理，助力企业精准把控生产节奏，实现智能化供应链管理。",
        },
      ],
    };
  },
  data() {
    return {
      tabs: ["锦瀚城乡建设", "金溆科技", "润玉空间"],
      activeName: "1",
      idx: 0,
      tbx: 0,
      ids: -1,
      tabListNew: [
        {
          img1: require("@/assets/newSite/isc/p1.png"),
          tit: "销售管理",
        },
        {
          img1: require("@/assets/newSite/isc/p2.png"),
          tit: "采购管理",
        },
        {
          img1: require("@/assets/newSite/isc/p3.png"),
          tit: "生产管理",
        },
        {
          img1: require("@/assets/newSite/isc/p4.png"),
          tit: "进销存",
        },
        {
          img1: require("@/assets/newSite/isc/p5.png"),
          tit: "报表分析",
        },
      ],

      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      baseImgUrl: "https://admin.veiban.com",
      caseList: [],
      caseA: {},
      caseB: {},
      caseC: {},
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    this.getCase();
  },
  methods: {
    handleClickChild() {
      this.$refs.mofooter.handleDeal(2);
    },
    //成功案例
    getCase() {
      const prams = {
        pageSize: 10,
        pageNo: 1,
        newsType: 0,
        productCode: "ISC",
      };
      caselist(prams).then((res) => {
        let list = res.data.list;
          if (list.length) {
            this.caseA = list[0] || {};
            this.caseB = list[1] || {};
            this.caseC = list[2] || {};
          }
          //取三条数据 边界判断
          if (list.length > 3) {
            this.caseList = list.slice(0, 3);
          }
          if (list.length == 0) {
            this.caseList = [
              { customer: "暂未配置客户" },
              { customer: "暂未配置客户" },
              { customer: "暂未配置客户" },
            ];
          }
          if (list.length == 1) {
            let lis = [
              { customer: "暂未配置客户" },
              { customer: "暂未配置客户" },
            ];
            this.caseList = list.concat(lis);
          }
          if (list.length == 2) {
            let lis = [{ customer: "暂未配置客户" }];
            this.caseList = list.concat(lis);
          }
          if (list.length == 3) {
            this.caseList = list;
          }
      });
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({ path, id: "123456" });
      window.open(routeData.href, "_blank");
    },
    handleSwitch(idx) {
      this.idx = idx;
    },
    handleTest() {
      window.open("https://apipecloud.veiban.com/login");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

    handleTab(index) {
      // this.idx = index;
      this.tbx = index;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
};
</script>
            
    <style lang="less" scoped>
.apipe-cloud-course-container-madeSolution {
  .pc-banner {
    height: 600px;
    background-image: url("../../assets/newSite/isc/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 18px;
        margin: 4px 0;
      }
      .exp {
        margin-top: 2rem;
        font-size: 14px;
        border: 1px solid white;
        display: inline-block;
        width: 100px;
        padding: 2px 0;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mobile-banner {
    height: 300px;
    width: 100%;
    background-image: url("../../assets/newSite/isc/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .title {
        font-size: 35px;
        text-align: left;
        color: white;
        font-family: Source Han Sans CN;
        font-weight: 200;
        margin-bottom: 30px;
      }
      .titwrap {
        padding: 30px 20px;
        .tit {
          font-size: 13px;
          margin: 5px 0;
        }
      }

      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }
  .pc-industry {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 40px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        background: #f6faff;
        height: 240px;
        width: 300px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100px;
      height: 100px;
    }
    h6 {
      margin-top: 0.5rem;
      font-size: 16px;
      font-weight: 700;
      color: rgba(51, 51, 51, 1);
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .mobile-industry {
    margin: 40px 0;
    .pro-tit {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 700;
      color: #333;
    }
    .col {
      .p-3 {
        background: #f6faff;
        margin: 10px;
      }
    }
    img {
      // width: 100px;
      // height: 100px;
    }
    h6 {
      font-family: Source Han Sans CN;
      font-weight: 200;
      color: #333333;
      font-size: 15px;
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .pc-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .tabs {
      display: flex;
      .items {
        flex: 25%;
        // height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        .tit {
          padding: 20px 0 16px 0;
          font-size: 18px;
          font-weight: 700;
        }
        &:hover {
          cursor: pointer;
        }
        img {
          width: 36px;
          height: 36px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px;
      .row {
        min-height: 300px;
        padding: 20px 100px;
        .mtit {
          text-align: left;
          font-size: 24px;
          font-weight: 500;
        }
        .mcot {
          text-align: left;
          margin: 20px 0 30px 0;
          color: #333333;
        }
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 120px;
            background: #1484ff;
            &:hover {
              background: #66b1ff;
            }
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .mobile-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 20px;
      color: #333;
      font-weight: 700;
    }
    .tabs {
      display: flex;
      width: 375px;
      overflow-x: scroll;
      overflow-y: hidden;
      margin: 0 auto;
      .items {
        width: 33%;
        flex-shrink: 0;
        // height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
        .tit {
          padding: 20px 0 16px 0;
          font-family: Source Han Sans CN;
          font-weight: 700;
          font-size: 16px;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px 0;
      .row {
        min-height: 300px;
        padding: 10px;
        h4 {
          font-family: Source Han Sans CN;
          font-weight: 700;
          color: #333333;
          font-size: 16px;
        }
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 100px;
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .pc-scene {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        padding: 0 !important;
      }
    }
    img {
      width: 100%;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }
  .mobile-scene {
    margin: 0 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        padding: 5px !important;
      }
    }
    img {
      width: 100%;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }

  .pc-product {
    .acticv {
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
      }
    }
    .pro-wrap {
      background: #f6faff;
      text-align: left;
      .container {
        display: flex;

        .cot {
          background: url("../../assets/newSite/home/pc.png");
          background-repeat: no-repeat;
          background-size: cover;
          height: 702px;
          width: 100%;
        }
        img {
          // height: 16rem;
        }
        .content {
          margin: 40px 0;
          width: 100%;
          .wrap {
            flex: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              font-family: Source Han Sans CN;
              font-weight: 200;
              color: #333333;
              width: 540px;
              margin-bottom: 20px;
              margin-top: 12px;
              font-size: 18px;
            }
          }
          .btn {
            width: 120px;
            background: #1484ff;
            height: 36px;
          }
        }
        .noData {
          padding: 60px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
  .mobile-product {
    .acticv {
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      padding: 1rem 0 0 0;
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
        font-family: Source Han Sans CN;
        font-weight: 700;
        font-size: 16px;
      }
    }
    .pro-tit {
      font-family: Source Han Sans CN;
      font-weight: 700;
      color: #333333;
      font-size: 20px;
      margin-bottom: 15px;
    }
    .pro-wrap {
      text-align: left;
      background: #f6faff;
      .container {
        display: flex;
        .content {
          padding: 2rem 1.5rem 1.5rem 1.5rem;
          width: 100%;
          img {
            width: 100%;
            // height: 20rem;
          }
          .p-content {
            color: #666;
            font-size: 0.8rem;
            font-family: "思源黑体";
            line-height: 1.6rem;
          }
          .btn {
            width: 120px;
            background: #1484ff;
            color: white;
          }
        }
        .noData {
          padding: 60px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
}
</style>
            